const Home = () => {
  const template = `<div class="leftHalf">
    <h1>Hazmic Investments</h1>
    <p>We are … Passionate about working directly with business owners as they think through raising capital for growth or potentially selling their business. </p></div>  
  `;

  return template;
};

export default Home;
