import Header from './components/Header';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Criteria from './components/Criteria';
import Contact from './components/Contact';
import Footer from './components/Footer';
import fullpage from 'fullpage.js';

import './scss/app.scss';

const app = () => {
  document.getElementById('header').innerHTML = Header();
  document.getElementById('section1').innerHTML = Home();
  document.getElementById('section2').innerHTML = AboutUs();
  document.getElementById('section3').innerHTML = Criteria();
  document.getElementById('section4').innerHTML = Contact();
  document.getElementById('section5').innerHTML = Footer();
};

new fullpage('#myFullpage', {
  controlArrows: false,
  sectionSelector: '.section',
  navigation: true,
  slidesNavigation: true,
  autoScrolling: true,
  sectionsColor: ['#ff5f45', '#0798ec', '#fc6c7c', 'grey'],
  keyboardScrolling: true,
  anchors: ['home', 'aboutUs', 'criteria', 'contactUs', 'footer'],
  menu: '#nav-ul',
});

//Init app
app();
