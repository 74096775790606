const AboutUs = () => {
  const template = `
    <h1>About Us</h1>
    <p>This is a boilerplate for a Section2</p>  
  `;

  return template;
};

export default AboutUs;
