const Contact = () => {
  const template = `
  <h1>Contact Us</h1>
  <div id="form">
    <form name="pitch" method="POST" data-netlify="true" netlify>
      <h1>SUBMIT YOUR BUSINESS</h1>

      <p>
        <label>Name: <input type="text" name="name" /></label>   
      </p>

      <p>
        <label>Email: <input type="email" name="email" /></label>
      </p>

      <p>
        <label>Phone Number: <input type="tel" name="tel" /></label>
      </p>

      <p>
        <label>Business Name: <input type="text" name="businessName" /></label>   
      </p>

      <p>
        <label>What type of investment are you seeking?: 
          <select name="investmentType" multiple>
            <option value="seed">Seed Investment</option>
            <option value="growth">Growth Investment</option>
          </select>
        </label>
      </p>

      <p>
        <label>Message: <textarea name="message"></textarea></label>
      </p>

      <p>
        <label>Upload Supporting Documents: <input type="file" name="fileToUpload" id="fileToUpload"></label>
      </p>

      <p>
        <button type="submit">Submit</button>
      </p>
      
    </form>
</div>  
  `;

  return template;
};

export default Contact;
