const Footer = () => {
  const template = `
    <h1>About Us</h1>
    <p>This is a boilerplate for a Section2</p>  
    <ul>
      <li>Home</li>
      <li>About Us</li>
      <li>Criteria</li>
      <li>Contact Us</li>
    </ul>
  `;

  return template;
};

export default Footer;
