const Criteria = () => {
  const template = `<div class="leftHalf">
    <h1>Criteria</h1>
    <p>Your service is technology enabled</p>
    <ul>
      <li>You design and configure technology into a solution delivered to an end user customer.</li>
      <li>You don't deliver a physical good to your customer.</li>
      <li>Your product or service is available on-demand to your customer.</li>
      <li>You use a recurring-revenue/subscription billing model</li>
    </ul> 
    </p> 
    </div>
  `;

  return template;
};

export default Criteria;
