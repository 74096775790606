const Header = () => {
  const template = `
  <header>
    <nav class="hide">
    <img src="" alt="logo" class="logo" />
    <ul class="nav-ul" id="nav-ul">
      <li data-menuanchor="home">
        <a href="#home" title="HOME">HOME</a>
      </li>
      <li data-menuanchor="aboutUs">
        <a href="#aboutUs" title="ABOUT US">ABOUT US</a>
      </li>
      <li data-menuanchor="criteria">
        <a href="#criteria" title="CRITERIA">CRITERIA</a>
      </li>
      <li data-menuanchor="contactUs">
        <a href="#contactUs" title="CONTACT US">CONTACT US</a>
      </li>
    </ul>
  </nav>
</header>
  `;

  return template;
};

export default Header;
